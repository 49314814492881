<template>
  <div class="job-detail">
    <NavBar />
    <div class="job-content">
      <div
        class="content-desc"
        v-if="
          job[tabIndex] && job[tabIndex].list && job[tabIndex].list[jobIndex]
        "
      >
        <div class="desc-title">
          {{ job[tabIndex].list[jobIndex].title }}
        </div>
        <div class="desc-tab">
          <span
            v-for="(item, index) in job[tabIndex].list[jobIndex].tab"
            :key="index"
            >{{ item }}</span
          >
        </div>
        <div
          class="desc-detail"
          v-for="(item, index) in job[tabIndex].list[jobIndex].content"
          :key="index"
        >
          <div class="detail-title">
            <span class="title-line"></span>
            <span class="title-text">{{ item.title }}</span>
          </div>
          <div v-html="item.text" class="detail-text"></div>
        </div>
      </div>
      <div class="content-footer">
        申请职位请发送简历至hr@peerfintech.cn<br />公司地址：江苏省南京市鼓楼区清江南路18号鼓楼创新广场B座10楼
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";
export default {
  components: {
    NavBar,
    Footer,
  },
  data() {
    return {
      job: [
        {
          tab: "校招",
          list: [
            {
              title: "区块链研发实习生",
              tab: ["南京", "研发部-技术产品组", "日薪110-160元/天"],
              content: [
                {
                  title: "职责描述",
                  text: "1、学习区块链相关知识； <br />2、参与区块链应用系统的设计与开发工作； <br />3、参与系统测试工作。",
                },
                {
                  title: "任职要求",
                  text: "1、	统招本科及以上学历，计算机相关专业；<br />2、熟悉常见数据结构与算法；<br />3、	熟练掌握c/c++、go、python、java中的至少一种； <br/>4、	满足正常出勤，至少实习半年；<br />5、	有实习经验者优先。",
                },
              ],
            },
            {
              title: "管培生",
              tab: ["南京", "技术岗/产品岗/综合岗"],
              content: [
                {
                  title: "技术岗（10k-20k）",
                  text: "具备扎实的技术理论知识，有一定的技术专长，对某一类技术有较深入的了解和实践应用；有较强的全局意识、解次问题能力以及项目推动意识，对区块链技术有浓厚的兴趣。",
                },
                {
                  title: "产品岗（8k-15k）",
                  text: "对互联网产品有极大热情，对产品设计过程的各种概念和知识有一定储备，对新鲜事物充满好奇，具备敏锐的市场洞察力，善于把握产品背后的商业价值；熟悉国内互联网发展历程，对各行业的发展模式有较多的了解并有自己的观点，对区块链技术有浓厚的兴趣。",
                },
                {
                  title: "综合岗（6k-12k）",
                  text: "有志在人力行政/市场/职能等岗位中长期发展并拥有相应的专业知识储备；具备较好的分析能力、解决问题能力以及合作服务意识、充分考虑他人，为企业创造价值。",
                },
              ],
            },
          ],
        },
        {
          tab: "社招",
          list: [
            {
              title: "售前工程师",
              tab: ["南京", "产品部", "11k-15k", "经验3-5年", "本科及以上"],
              content: [
                {
                  title: "职责描述",
                  text: "1、参与公司知识库建设及管理，各类文档、方案的标准化工作；<br />2、参与重点行业及客户对区块链产品的需求调研、产品规划与设计工作研究工作，挖掘需求并形成解决方案；<br/>3、负责完成与外部合作机构的对接工作，包括：合作洽谈，尽职调查，产品设计，业务流程梳理，具体合作方案的制定，并编写需求文档等相关工作 ；<br/>4、配合编写PPT、WORD阐述产品；<br/>5、参与客户交流、客户关系维护等工作。",
                },
                {
                  title: "任职要求",
                  text: "1、 计算机、互联网、电子、电气相关专业，本科及以上学历，了解区块链技术优先；<br/>2、 有投标文件、技术文档、解决方案、咨询方案编写工作经验者优先；<br/>3、 熟练掌握文档编辑、绘图工具，熟悉axure优先；<br/>4、 优秀的学习、归纳总结、文字组织驾驭、逻辑分析能力；<br/>5、 良好的沟通协调能力及团队合作能力，工作态度认真，严谨。",
                },
              ],
            },
            {
              title: "项目方案工程师",
              tab: ["南京", "产品部", "8k-10k", "经验1-3年", "本科及以上"],
              content: [
                {
                  title: "职责描述",
                  text: "1、配合业务部门完成项目招投标工作，包括技术方案的编制、技术标书应答、项目报价等相关工作;<br/>2、负责公司资质材料和招投标文件的维护、更新和归档管理等工作;<br/>3、负责公司相关课题申报、公司技术产品及研发成果的总结整理，并按规定如期完成课题内容制作、文件盖章投递等工作;<br/>4、负责公司方案库的维护、整理，能够熟悉公司技术路线及相关的产品、解决方案、历史项目案例情况。",
                },
                {
                  title: "任职要求",
                  text: "1、工作严谨，做事稳重，能够严守工作秘密，有一-定的文字功底、熟练掌握办公软件、语言表达能力和理解能力强;<br/>2、了解并热爱区块链行业，能够主动学习相关行业知识并学会归纳总结项目经验;<br/>3、本科及以上学历，计算机相关专业毕业，熟悉标书文件制作规范，熟悉项目招投标方面的工作流程，--年及以上招投标或项目申报工作经验;<br/>4、具有区块链、金融、政务、教育、智慧城市、乡村振兴、物联网、软件集成等行业标书工作经验，并能独立完成招投标者优先;<br/>5、能适应短期出差和加班。",
                },
              ],
            },
            {
              title: "Java研发工程师",
              tab: [
                "南京",
                "研发部-技术产品组",
                "10k-15k（12个月）",
                "经验1-3年",
                "本科及以上",
              ],
              content: [
                {
                  title: "任职要求",
                  text: "1. 统招本科及以上学历，计算机相关专业毕业优先，1年以上后端Java开发经验；<br/>2. 熟练掌握Java技能，熟悉Java常用库、Java项目构建、工程化机制及工具使用；<br/>3. 熟练使用springboot工程项目构建，注解，拦截器等使用；<br/>4. 熟练掌握Linux常用命令的使用及参数配置，熟悉shell脚本语法；<br/>5. 熟悉常用的数据结构及算法，熟悉软件系统设计方法及理论，能独立完成系统模块开发；<br/>6. 熟悉SQL和NoSQL的一种或多种，熟悉数据库设计的基本方法及理论；<br/>7. 熟悉Git、MarkDown、JIRA、Docker、CI/CD产品研发管理工具及流程；<br/>8. 具有良好的优化代码和代码层次结构划分能力，清晰的代码架构思想；<br/>9. 具有规范的代码编码习惯，严谨的软件工程项目的规范、流程和意识；<br/>10. 热衷技术的研究和创新，具有很强的学习能力和技术敏感度，抗压能力强，有正确的价值观。",
                },
              ],
            },
            {
              title: "前端工程师",
              tab: [
                "南京",
                "研发部-技术产品组",
                "9k-14k",
                "经验1-3年",
                "本科及以上",
              ],
              content: [
                {
                  title: "职责描述",
                  text: "1、与设计师协作，根据设计完成Web、h5页面制作；配合后台开发人员实现产品交互界面；<br/>2、针对UI设计图能够进行切图以及简单的图片处理，编写CSS规范，并形成CSS基础框架，精通跨浏览器的Web前端开发；<br/>3、负责网站、h5及交互方面的开发维护；<br/>4、负责改进产品的用户体验，优化前端代码；<br/>5、能够理解后端架构，与后端工程师配合，为项目提供最优化的技术解决方案；和UI设计并进行分析，优化前端用户操作体验。",
                },
                {
                  title: "任职要求",
                  text: "1、本科以上学历，计算机相关专业优先，1年以上前端开发工作经验；<br/>2、熟悉react或vue技术栈，熟悉常用前端技术框架，熟悉HTML5、JavaScript、CSS、Ajax、JQuery等语言和技术；<br/>3、熟悉微信小程序开发部署；<br/>4、具有良好的编程习惯和较强的的学习能力；具有较高的积极性、良好的心态和沟通能力；<br/>5、有浏览器兼容性开发和调试经验，熟练使用各种浏览器调试工具。",
                },
              ],
            },
          ],
        },
      ],
      tabIndex: null,
      jonIndex: null,
    };
  },
  mounted() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    this.tabIndex = this.$route.query.tabIndex;
    this.jobIndex = this.$route.query.jobIndex;
  },
};
</script>

<style scoped lang="scss">
.job-detail {
  .content-width {
    width: 1200px;
    margin: 0px auto;
  }
  .job-content {
    background-color: #f8fafc;
    margin-top: 64px;
    padding-top: 32px;
    .content-desc {
      @extend .content-width;
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      padding: 50px;
      margin-bottom: 50px;
      .desc-title {
        font-size: 26px;
        font-family: "PingFangSC-Medium";
        color: #000000;
        line-height: 32px;
        margin-bottom: 20px;
      }
      .desc-tab {
        padding-bottom: 20px;
        border-bottom: 1px solid #dbdbdb;
        span {
          padding-right: 12px;
          padding-left: 12px;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.65);
          line-height: 20px;
          border-right: 1px solid #d8d8d8;
        }
        span:first-child {
          padding-left: 0px;
        }
        span:last-child {
          padding-right: 0px;
          border-right: none;
        }
      }
      .desc-detail {
        .detail-title {
          padding: 30px 0px 12px;
          display: flex;
          align-items: center;
          font-size: 18px;
          font-family: "PingFangSC-Medium";
          color: #000000;
          line-height: 25px;
          .title-line {
            width: 4px;
            height: 16px;
            background: #1876ff;
            border-radius: 2px;
            margin-right:10px;
          }
        }
        .detail-text {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.65);
          line-height: 26px;
        }
      }
    }
    .content-footer {
      @extend .content-width;
      border-top: 1px solid #dbdbdb;
      padding: 39px 0px 70px;
      text-align: center;
      font-size: 14px;
      color: #000000;
      line-height: 26px;
    }
  }
}
</style>
